import React from "react"
import Soldier from "../../assets/images/soldier.jpg"
import Benefits from "../../components/Benefits/Benefits"
import Staff from "../../components/Staff/Staff"
import Footer from "../../components/Footer/Footer"
import Contact from "../../components/Contact/Contact"
import Gifts from "../../components/Gifts/Gifts"
import Events from "../../components/Events/Events"
import Offers from "../../components/Offers/Offers"
import "./Home.scss"

const Home = () => {
  return (
    <main className="Home">
      <div className="image">
        <img src={Soldier} alt="Soldier" loading="lazy" />
        <div className="title">
          <h1 className="name">STRZELNICA BYDGOSZCZ</h1>
          <h2 className="description">SZKOLENIA I TRENINGI STRZELECKIE</h2>
          <h2 className="description">info@strzelnicabydgoszcz.pl</h2>
          <h2 className="description">605 437 446</h2>
        </div>
        <div className="overlay"></div>
      </div>
      <Benefits />
      <Staff />
      <Gifts />
      <Offers />
      {/* <Events /> */}
      <Contact />
      <Footer />
    </main>
  )
}

export default Home

import React from "react"
import styled from "styled-components"
import background from "../../../assets/images/background.jpg"

const CardBack = ({ dataTitle, data, footer }) => {
  return (
    <Box>
      <img src={background} alt="" className="img" />
      <div className="container">
        <p className="title">{dataTitle}</p>
        <div className="data">
          {data?.map((row, idx) => {
            return <p key={idx}>{row}</p>
          })}
        </div>
        <p className="footer">{footer}</p>
      </div>
    </Box>
  )
}

export default CardBack

const Box = styled.div`
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: white;
  color: white;
  border-radius: 2rem;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);

  & > .img {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 1rem;

    display: grid;

    .title {
      font-size: 1.5rem;
      white-space: nowrap;
    }

    .footer {
      margin: auto;
      /* text-align: center; */
      font-size: 1rem;
      /* white-space: nowrap; */
    }

    .data {
      p {
        color: white;
        white-space: wrap;
        font-size: 1.1rem;
      }

      p:not(:last-child) {
        /* margin-bottom: 0.5rem; */
      }
    }
  }
`
